import { Component, Input, OnInit } from "@angular/core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { PlansService } from "../plans.service";
import { IGetUserCacheResponse } from "../app.model";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  public sendFeedbackInProgress = false;
  public forRevertToClassicApps = false;
  public userAllowContact = true;
  public feedback;
  public feedbackMsg = "Sending feedback.....";
  faInfoCircle = faInfoCircle;
  @Input() userCacheObj: IGetUserCacheResponse;

  constructor(private _plansService: PlansService) {}

  ngOnInit(): void {}

  dialogAction() {
    this.sendFeedbackInProgress = true;
    // Get required email fields from state store
    this._plansService
      .SendFeedback(this.feedback, this.userAllowContact, this.userCacheObj)
      .subscribe(
        (response: any) => {
          // Don't bother with the notification if this feedback is for reversion to maps classic.
          this.feedbackMsg = "Your feedback has been sent. Thank you.";
        },
        () => {
          // Don't unset progress flag, as the dialog is going to close. It looks better.
          this.close();
        }
      );
  }

  close() {}
}
