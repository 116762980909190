import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as ol from "ol";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
//import { Fill, Stroke } from 'ol/style';
import { Collection, Feature } from "ol";
import { Draw, Modify, Select, DragPan, Snap } from "ol/interaction";
import { platformModifierKeyOnly } from "ol/events/condition";
import { defaults as defaultInteractions } from "ol/interaction";
import { createBox } from "ol/interaction/Draw";
//import GeoJSON from 'ol/format'
import { OverviewMap, ScaleLine, Zoom } from "ol/control";
//import { union as olUnion } from 'ol/extent';

import Source from "ol/source/Source";
import {
  FeatureAction,
  GeometryType,
  IMapUndoRedoSnapshot,
  ISearchResult,
  IWms,
  PlanColorType,
  PlanTemplates,
  PlansProductId,
  Shape_Type,
} from "../app.model";
import TileWMS from "ol/source/TileWMS";
import { MappingService } from "../mapping.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import { UndoRedoService } from "../undoredo.service";

import WKT from "ol/format/WKT";
import { easeIn } from "ol/easing.js";
import Polygon, { fromCircle } from "ol/geom/Polygon";
import { Coordinate } from "ol/coordinate";
import { getLength } from "ol/sphere";
import { getCenter, containsExtent, getIntersection } from "ol/extent";
import { singleClick } from "ol/events/condition";
import MultiPolygon from "ol/geom/MultiPolygon";
import Geometry from "ol/geom/Geometry";
import { Style, Stroke, Fill, Circle as Circlestyle } from "ol/style";
import Point from "ol/geom/Point";
import Circle from "ol/geom/Circle";
import LineString from "ol/geom/LineString";
import { FeatureService } from "../feature.service";
import ol_interaction_Transform from "ol-ext/interaction/Transform";
import * as io from "jsts/org/locationtech/jts/io";
import { GeoJSONReader, GeoJSONWriter } from "jsts/org/locationtech/jts/io";
import OverlayOp from "jsts/org/locationtech/jts/operation/overlay/OverlayOp";
import { GeoJSON } from "ol/format";
import * as olProj from "ol/proj";
import { style } from "@angular/animations";

//import { createDefaultStyle } from 'ol/style';

enum BlockProductScale {
  BASE_500_SIZE = 80, //80m block
  SCALE_500_BASE = 1, //for block plan
  SCALE_500 = 0.4,
  SCALE_1250 = 1,
  SCALE_2500 = 2,
  BASE_1250_A4_SIZE = 200,
  BASE_1250_A3_SIZE = 282,
  BASE_1250_A2_SIZE = 400,
  Large_1250_A4_Width = 237,
  Large_1250_A4_HEIGHT = 345.99,
  Large_1250_A3_HEIGHT = 500,
  Large_1250_A3_Width = 346,
  SIZE_2HA_1250 = 141.421,
}

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  private _map: Map;
  private _source: Source;
  private _source2: Source;
  private _extent: [number, number, number, number] = [0, 0, 700000, 1344000];
  private _centre: [number, number] = [457418, 303435];
  private _defaultZoom: number = 8;
  private _minZoom: number = 6;
  private _maxZoom: number = 25;
  private _guid: string;
  private _mapBaseLayer: any;
  private _vectorLayer;
  private _vectorLayer2;
  private _drawInteraction: Draw;
  private _selectInteraction: Select;
  private _modifyInteraction: Modify;
  removeDrawInteraction: boolean = false;
  private _feature;
  private _featureCount = 0;
  private _center;
  //private _strokeColor: string = '#FA0000';
  private _strokeColor: string = "blue";
  private _strokeWidth: any = 2;
  private _layers: any;
  private _baseTileLayer: TileLayer<any>;
  private _layerIndex: number = 0;
  private _zoomControl: any;
  public blockandLocationSelected = false;
  public onlyBlockPlanSelected = false;
  public locationSelected = false;
  public showOnMap = false;
  private _planSelected = false;

  private _pickFeatureGeom;
  @Output() public featureSelected = new EventEmitter<any>();
  @Output() public featureDeselected = new EventEmitter<any>();
  @Output() public actionFinished = new EventEmitter<any>();
  @Output() public updateLegendColor = new EventEmitter<any>();
  @Output() public areaDrawn = new EventEmitter<any>();
  @Output() public mapCreated = new EventEmitter<any>();
  @Output() public hmlrSelected = new EventEmitter<any>();
  @Output() public mapMoved = new EventEmitter<any>();

  customPlanSelected: boolean = false;
  selectedFeature: Feature<Geometry>;
  colorChanged: boolean;
  private _pickFeatureURL: any;
  // Flag to indicate modify key (typically Ctrl) is being pressed during an interaction
  modifyKeyPressed: boolean = false;
  private _geoms = [];
  pickfeatureSelected: boolean = false;
  private _vectorLayer3: VectorLayer<any>;
  addingText: boolean;
  textArray: any = [];
  overlayPosition: any;
  private _wktReader = new io.WKTReader();
  snapVectorSource: VectorSource<Geometry>;
  private _snapInteraction: Snap;
  private _enableSnap: boolean = false;
  cursorType: string = "default";
  private _shapeType: Shape_Type;

  constructor(
    private _mappingService: MappingService,
    private _route: ActivatedRoute,
    private _cookieService: CookieService,
    private _undoRedoService: UndoRedoService,
    private _featureService: FeatureService
  ) {}

  ngOnInit(): void {
    //get guid
    this._route.queryParams.subscribe((params) => {
      let guidName = environment.configurations.appConfig.cookies.guid.name;
      this._guid = params.guid
        ? params.guid
        : this._cookieService.get(guidName);
    });
    const source = new VectorSource();
    const vector = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#FA0000",
          width: 2,
        }),
      }),
    });
    this._source = source;
    this._vectorLayer = vector;
    this._source2 = new VectorSource();
    this._vectorLayer2 = new VectorLayer({
      source: <VectorSource>this._source2,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: this._strokeColor,
          width: this._strokeWidth,
        }),
      }),
    });

    let source3 = new VectorSource();
    this._vectorLayer3 = new VectorLayer({
      source: <VectorSource>source3,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: this._strokeColor,
          width: this._strokeWidth,
        }),
      }),
    });

    //call getmapprovders first to get mapping layer for plans and then create map with the new layer obtained
    this._mappingService.GetMapProviders(this._guid).subscribe(
      (responseJson) => {
        if (responseJson.layers && responseJson.layers.length > 0) {
          this._layers = responseJson.layers;
          this._mapBaseLayer = responseJson.layers[this._layerIndex];
          this.createMapWithService();
          if (responseJson.pickFeatureURL && responseJson.allowPickFeature) {
            this._pickFeatureURL = responseJson.pickFeatureURL;
          }
        } else {
          console.log("no result");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //create map with getmapprovider
  async createMapWithService() {
    let source = this.getWmsSource(this._mapBaseLayer);
    // let snap_source = new Source()
    let tileLayer = new TileLayer({
      source: source,
    });
    this._baseTileLayer = tileLayer;

    this._zoomControl = new Zoom();

    this._map = new Map({
      layers: [
        this._baseTileLayer,
        this._vectorLayer,
        this._vectorLayer2,
        this._vectorLayer3,
      ],
      target: "js-map",
      controls: [],
      view: new View({
        center: this._centre,
        zoom: this._defaultZoom,
        minZoom: this._minZoom,
        maxZoom: this._maxZoom,
      }),
      interactions: defaultInteractions({
        doubleClickZoom: false,
        dragPan: true,
        mouseWheelZoom: false,
        pinchZoom: false,
        shiftDragZoom: false,
      }),
    });

    this.mapCreated.emit();
    this.addSelectInteraction();
    this._map.on("moveend", () => {
      // alert("map moved")
      //check if the new extent has gone beyond initial extent and reset
      let currentExtent = this._map
        .getView()
        .calculateExtent(this._map.getSize());
      if (this._planSelected && !containsExtent(this._extent, currentExtent)) {
        let restrictedExtent = getIntersection(currentExtent, this._extent);
        let newCenter = getCenter(restrictedExtent);
        this._map.getView().setCenter(newCenter);
      } else {
        //if not planselected move center and extent
        if (!this._planSelected) {
          let newCenter = this._map.getView().getCenter();
          this.setCenterFromXY(newCenter);
          this._extent = currentExtent as [number, number, number, number];
        }
      }
      if (!this.customPlanSelected) {
        let drawBlock = sessionStorage.getItem("showonmap");

        if (drawBlock) {
          sessionStorage.removeItem("showonmap");
        } else {
          if (this.locationSelected) {
            let newCenter = this._map.getView().getCenter();
            this.setCenterFromXY(newCenter);
          }
        }
      }
      //emit event to let landingpage know that map has moved
      this.mapMoved.emit();
    });
  }

  async getSnapGeom() {
    return new Promise((resolve, reject) => {
      //open httprequest and get the response
      var xhttp = new XMLHttpRequest();

      var callNodesUrl =
        "https://api.emapsite.com/dataservicenoauth/api/query/mapshop_plansahead?source=mapshop_callnodes&Guid=" +
        this._guid +
        "&xl=" +
        this._extent[0] +
        "&xh=" +
        this._extent[2] +
        "&yl=" +
        this._extent[1] +
        "&yh=" +
        this._extent[3] +
        "&xl=" +
        this._extent[0] +
        "&xh=" +
        this._extent[2] +
        "&yl=" +
        this._extent[1] +
        "&yh=" +
        this._extent[3];
      xhttp.open("GET", callNodesUrl, true);
      xhttp.send("");
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          var wkt =
            this.responseXML.querySelector("column[name=wkt]").innerHTML;
          resolve(wkt);
        } else if (this.status == 400 || this.status == 500) {
          reject("Failed to get nodes. Status: " + this.status);
        }
      };
    });
  }

  //fetch snapping nodes and show on the map
  async showSnapLayer(layerVisible: boolean) {
    this._enableSnap = true;
    await this.getSnapGeom().then((snap_nodes) => {
      let wkt = new WKT();

      const geometry = wkt.readGeometry(snap_nodes, {
        dataProjection: "EPSG:27700",
        featureProjection: "EPSG:3857",
      });
      let feature = new Feature(geometry);
      this.snapVectorSource = new VectorSource({
        features: [feature],
      });
      const snapLayer = new VectorLayer({
        source: this.snapVectorSource,
      });
      this._map.addLayer(snapLayer);
      snapLayer.setVisible(layerVisible);
    });
  }

  removeSnapLayer() {
    this._enableSnap = false;
    //if snapLayer exists remove from map
    let maplayers = this._map.getAllLayers();
    if (maplayers.length > 4) {
      this._map.removeLayer(maplayers[4]);
      this._snapInteraction && this._snapInteraction.setActive(false);
    }
  }

  setSnapLayerVisible(visible: boolean) {
    let maplayers = this._map.getAllLayers();
    if (maplayers.length > 4) {
      maplayers[4].setVisible(visible);
    }
  }

  getWmsSource(mapLayer: IWms): TileWMS {
    let urlParts = mapLayer.url.split("?");
    let newUrl = urlParts[0] + "mapshop_mm_f38ea9ea4880?" + urlParts[1];
    // added a condition for new webservices
    if (mapLayer.requireAuth) {
      return new TileWMS({
        //url: mapLayer.url + '&SRS=EPSG:27700',
        url: mapLayer.url, //changed for new webservices
        params: {
          LAYERS: mapLayer.layer,
          VERSION: "1.1.1",
          TILED: true,
          FORMAT: "image/png",
          WIDTH: 256,
          HEIGHT: 256,
        },
        serverType: "geoserver",
        tileLoadFunction: function (tile: any, src) {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", src);
          xhr.responseType = "arraybuffer";
          xhr.setRequestHeader("Authorization", mapLayer.apiKey);
          xhr.onload = function () {
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], { type: "image/png" });
            var urlCreator = window.URL;
            var imageUrl = urlCreator.createObjectURL(blob);
            tile.getImage().src = imageUrl;
          };
          xhr.send();
        },
      });
    } else {
      return new TileWMS({
        url: newUrl + "&SRS=EPSG:27700",
        //url : mapLayer.url,
        params: {
          LAYERS: mapLayer.layer,
          VERSION: "1.1.1",
          TILED: true,
          FORMAT: "image/png",
          WIDTH: 256,
          HEIGHT: 256,
        },
        serverType: "geoserver",
      });
    }
  }

  //method to delete a drwan shape
  deleteFeature() {
    let layers = this._map.getLayers().getArray();
    let layer2: VectorLayer<any> = <VectorLayer<any>>layers[2];
    //if there's any feature selected in layer2 then delete it
    if (
      this.selectedFeature &&
      layer2.getSource().hasFeature(this.selectedFeature)
    ) {
      layer2.getSource().removeFeature(this.selectedFeature);
      this._undoRedoService.pushCurrentMapState(this.getMapState());
      this._pickFeatureGeom = null; //removing selected feature history
    }
    this.actionFinished.emit();
  }

  deleteHmlrPolygon() {
    let layers = this._map.getLayers().getArray();
    let layer3: VectorLayer<any> = <VectorLayer<any>>layers[3];
    layer3.getSource().clear();
  }

  //redo
  redo() {
    if (this._undoRedoService.canRedo()) {
      const mapState = this._undoRedoService.redo();
      if (mapState) {
        this.setMapState(mapState);
      }
      setTimeout(() => {
        this.actionFinished.emit();
      }, 500);
    }
  }
  //undo drawn shape
  undo() {
    if (this._undoRedoService.canUndo()) {
      let state = this._undoRedoService.undo();
      this.setMapState(state);
    }
    setTimeout(() => {
      this.actionFinished.emit();
    }, 500);
  }

  drawShape(isLine?: boolean) {
    this.cursorType = "crosshair";
    this._shapeType = isLine ? Shape_Type.LineString : Shape_Type.Polygon;
    if (this._drawInteraction) {
      this._map.removeInteraction(this._drawInteraction);
    }
    if (this.pickfeatureSelected) {
      this._selectInteraction.setActive(true);
      this._map.addInteraction(this._selectInteraction);
      this.pickfeatureSelected = false;
    }
    const drawInteraction = new Draw({
      type: isLine ? GeometryType.LINE_STRING : GeometryType.POLYGON,
      // source: <VectorSource>this._source2,
      style: new Style({
        stroke: new Stroke({
          color: this._strokeColor,
          width: this._strokeWidth,
        }),
        image: new Circlestyle({
          radius: 5,
          fill: new Fill({
            color: this._strokeColor,
          }),
        }),
      }),
      snapTolerance: 2,
    });
    this._drawInteraction = drawInteraction;
    this._map.addInteraction(drawInteraction);

    //if snap enabled then add snap interaction
    if (this._enableSnap) {
      this._snapInteraction = new Snap({
        source: this.snapVectorSource,
      });
      this._snapInteraction.setActive(true);
      this._map.addInteraction(this._snapInteraction);
    }

    drawInteraction.on("drawend", (e) => {
      //reset the cursor
      this.cursorType = "default";
      this._featureCount++;
      this._feature = new Feature({
        geometry: e.feature.getGeometry(),
      });
      // Modify the feature's geometry to include the fill area

      if (!isLine) {
        var polygonGeometry = e.feature.getGeometry() as Polygon;
        // Get the original exterior ring
        var exteriorRing = polygonGeometry.getLinearRing(0);

        // Create an array to store the modified rings
        var modifiedRings = [exteriorRing.clone()]; // Clone the original exterior ring

        for (var i = 1; i < polygonGeometry.getLinearRingCount(); i++) {
          // Clone each interior ring and add it to the modified rings array
          var interiorRing = polygonGeometry.getLinearRing(i);
          modifiedRings.push(interiorRing.clone());
        }

        // Create a new polygon geometry with the modified rings
        var fillGeometry = new Polygon(
          modifiedRings.map((ring) => ring.getCoordinates())
        );
        this._feature.setGeometry(fillGeometry);
        //this._feature = e.feature;
      } else {
        var lineGeometry = e.feature.getGeometry() as LineString;
        this._feature.setGeometry(lineGeometry);
      }
      let style = new Style({
        stroke: new Stroke({
          color: this._strokeColor,
          width: this._strokeWidth,
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.01],
        }),
      });
      // this._feature = new Feature(e.feature);
      this._feature.setStyle(style);
      this._feature.set("originalStyle", style);
      this._feature.setId("ShapeFT" + this._featureCount);
      this._vectorLayer2.getSource().addFeature(this._feature);

      drawInteraction.setActive(false);
      this._map.removeInteraction(drawInteraction);
      const modify = new Modify({ source: <VectorSource>this._source2 });
      this._map.addInteraction(modify);
      this._undoRedoService.pushCurrentMapState(this.getMapState());

      //deselect button
      this.actionFinished.emit();
    });
  }

  drawCircle(isRectangle: boolean = false) {
    this._shapeType = isRectangle ? Shape_Type.Rectangle : Shape_Type.Circle;

    if (this._drawInteraction) {
      this._map.removeInteraction(this._drawInteraction);
    }
    if (this.pickfeatureSelected) {
      this._selectInteraction.setActive(true);
      this._map.addInteraction(this._selectInteraction);
      this.pickfeatureSelected = false;
    }
    const drawInteraction = new Draw({
      type: GeometryType.CIRCLE,
      source: <VectorSource>this._source2,
      geometryFunction: isRectangle ? createBox() : null,
      style: new Style({
        stroke: new Stroke({
          color: this._strokeColor,
          width: this._strokeWidth,
        }),
      }),
    });
    this._drawInteraction = drawInteraction;
    this._map.addInteraction(drawInteraction);

    drawInteraction.on("drawend", (e) => {
      this._featureCount++;
      let style = new Style({
        stroke: new Stroke({
          color: this._strokeColor,
          width: this._strokeWidth,
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.01],
        }),
      });
      this._feature = new Feature(e.feature);
      this._feature.setId("ShapeFT" + this._featureCount);
      this._feature.setStyle(style);
      this._feature.set("originalStyle", style);
      e.feature.setStyle(style);
      e.feature.set("originalStyle", style);
      e.feature.setId("ShapeFT" + this._featureCount);

      (<VectorSource>this._source2).addFeature(e.feature);
      drawInteraction.setActive(false);
      drawInteraction.setActive(false);
      const modify = new Modify({ source: <VectorSource>this._source2 });
      this._map.addInteraction(modify);
      this._modifyInteraction = modify;
      const transform = new ol_interaction_Transform({
        translateFeature: e.feature,
        scale: true,
        rotate: true,
        translate: false,
        stretch: true,
        keepAspectRatio: true,
        filter: function (feature: Feature) {
          return feature.get("name") !== "hmlrPolygon";
        },
      });
      transform.setActive(true);
      this._map.addInteraction(transform);
      this._undoRedoService.pushCurrentMapState(this.getMapState());

      this.actionFinished.emit();
    });
  }
  // Set the current map state
  public setMapState(mapState: IMapUndoRedoSnapshot) {
    // Remove all features, maintaining any active draw interaction
    this.removeAllFeatures(false);
    // No map state implies start state - already reached after removing all features, above.
    if (mapState) {
      for (let i = 0, ii = mapState.features.getLength(); i < ii; i++) {
        let feature = new Feature(mapState.features.item(i).getGeometry());
        feature.setId(mapState.features.item(i).getId());
        let style = mapState.features.item(i).getStyle();
        feature.setStyle(style);
        feature.set("originalStyle", style);
        this._feature = feature;
        this._vectorLayer2.getSource().addFeature(feature);
      }
    }
  }

  public removeAllFeatures(
    removeDrawInteraction: boolean = true,
    undoable: boolean = false,
    removeMasks: boolean = true
  ) {
    if (this._map) {
      if (this._drawInteraction && removeDrawInteraction) {
        this._map.removeInteraction(this._drawInteraction);
        this._drawInteraction = null;
      }
    }
    this._vectorLayer2.getSource().clear();
  }
  public getMapState(): IMapUndoRedoSnapshot {
    let features: Array<any> = this._vectorLayer2.getSource().getFeatures();

    let featuresCopy: Collection<Feature> = new Collection<Feature>();
    for (let i = 0, ii = features.length; i < ii; i++) {
      let id = features[i].getId();
      let origStyle = features[i].get("originalStyle");
      let featureClone = features[i].clone();
      featureClone.setId(id);
      featureClone.set("originalStyle", origStyle);
      featuresCopy.push(featureClone);
    }
    return {
      features: featuresCopy,
      centre: [
        this._map.getView().getCenter()[1],
        this._map.getView().getCenter()[0],
      ],
      zoomLevel: this._map.getView().getZoom(),
    };
  }
  //add hmlr polygon
  public addFeatureAsWkt(resultObj: ISearchResult, centre: boolean = false) {
    // Generate a feature out of the Wkt and set styles for it
    let titles = resultObj.TitleNumbers;
    this.removeAllFeatures(false);
    titles.forEach((title) => {
      if (title.Wkt) {
        let wkt = new WKT();
        let feature = new Feature(wkt.readFeature(title.Wkt).getGeometry());
        let center = [resultObj.X, resultObj.Y];
        this._center = center;
        let style = new Style({
          stroke: new Stroke({
            color: "#FA0000",
            width: 3,
          }),
          fill: new Fill({
            color: [255, 255, 255, 0.01],
          }),
        });
        feature.setStyle(style);
        feature.set("originalStyle", style);
        feature.set("name", "hmlrPolygon");
        this._vectorLayer3.getSource().addFeature(feature);
      }
    });

    this.centreMapAndZoom(this._center);
  }

  private centreMapAndZoom(centre: Coordinate) {
    if (this._map) {
      let view = this._map.getView();
      this._map.renderSync();
      view.animate({
        center: centre,
        duration: 1000,
        easing: easeIn,
      });
    }
  }

  drawBlockPlanArea(fitToExtent: boolean) {
    //draw a square of 80m * 80m around the center and readjust the map view
    const infoStyleFunction: (feature: Feature, resolution: number) => Style[] =
      function () {
        return [
          new Style({
            stroke: new Stroke({
              color: fitToExtent ? "transparent" : "#406782",
              width: 2,
              lineDash: [5, 5],
            }),
          }),
        ];
      };
    // Add information feature according to type
    let bufferInfoFeature = this.getBlockPlanFeature(
      BlockProductScale.BASE_500_SIZE,
      BlockProductScale.SCALE_500_BASE
    );
    let extent = bufferInfoFeature.getGeometry().getExtent();

    bufferInfoFeature.setStyle(infoStyleFunction);
    bufferInfoFeature.setId(2);
    let bufferSize =
      BlockProductScale.BASE_500_SIZE * BlockProductScale.SCALE_500;
    bufferInfoFeature.set("description", bufferSize + "m buffer");
    this._vectorLayer.getSource().addFeature(bufferInfoFeature);
    if (fitToExtent) {
      this._extent = extent as [number, number, number, number];
      this._map.getView().fit(extent);
      this._map.renderSync();
      this._map.getView().animate({
        //  center: centre,
        duration: 1000,
        easing: easeIn,
      });
    }
    setTimeout(() => {
      this.setZoomLevels();
    }, 2000);
  }

  drawLocationPlanArea(papersize, scale) {
    this.removeLocationFeature();
    this.resetZoomLevels();

    if (sessionStorage.getItem("x")) {
      this._center = [
        Number(sessionStorage.getItem("x")),
        Number(sessionStorage.getItem("y")),
      ];
      sessionStorage.removeItem("x");
    }
    const infoStyleFunction: (feature: Feature, resolution: number) => Style[] =
      function () {
        return [
          new Style({
            stroke: new Stroke({
              color: "transparent",
              width: 2,
              lineDash: [5, 5],
            }),
          }),
        ];
      };
    let scale_factor = BlockProductScale.SCALE_1250;
    let size_key = "BASE_1250_" + papersize + "_SIZE";
    switch (scale) {
      case "1:1250":
        scale_factor = BlockProductScale.SCALE_1250;
        break;
      case "1:2500":
        scale_factor = BlockProductScale.SCALE_2500;
        break;
      case "1:500":
        scale_factor = BlockProductScale.SCALE_500;
        // size_key = 'BASE_500_SIZE'
        break;
    }

    let bufferInfoFeature = this.getBlockPlanFeature(
      BlockProductScale[size_key],
      scale_factor
    );
    let extent = bufferInfoFeature.getGeometry().getExtent();
    this._extent = extent as [number, number, number, number];

    this._map.getView().fit(extent, {
      duration: 500, // Animation duration in milliseconds (optional)
      easing: function (t) {
        return t; // Linear easing function
      },
    });
    this._map.renderSync();

    setTimeout(() => {
      this.areaDrawn.emit(this._extent);
    }, 600);

    bufferInfoFeature.setStyle(infoStyleFunction);
    bufferInfoFeature.setId(1);
    let bufferSize =
      BlockProductScale.BASE_1250_A4_SIZE * BlockProductScale.SCALE_1250;
    bufferInfoFeature.set("description", bufferSize + "m buffer");
    this._vectorLayer.getSource().addFeature(bufferInfoFeature);

    //set zoom level
    setTimeout(() => {
      this.setZoomLevels();
    }, 2000);
  }

  drawStandard2haPlanArea() {
    this.removeLocationFeature();
    if (sessionStorage.getItem("x")) {
      this._center = [
        Number(sessionStorage.getItem("x")),
        Number(sessionStorage.getItem("y")),
      ];
      sessionStorage.removeItem("x");
    }
    const infoStyleFunction: (feature: Feature, resolution: number) => Style[] =
      function () {
        return [
          new Style({
            stroke: new Stroke({
              color: "transparent",
              width: 2,
              lineDash: [5, 5],
            }),
          }),
        ];
      };

    let bufferInfoFeature = this.getBlockPlanFeature(
      BlockProductScale.SIZE_2HA_1250,
      BlockProductScale.SCALE_1250
    );
    let extent = bufferInfoFeature.getGeometry().getExtent();
    this._extent = extent as [number, number, number, number];

    setTimeout(() => {
      this._map.getView().fit(extent);
      this._map.renderSync();
      this._map.getView().animate({
        //  center: centre,
        duration: 200,
        easing: easeIn,
        //  zoom: zoom
      });
    }, 300);

    bufferInfoFeature.setStyle(infoStyleFunction);
    bufferInfoFeature.setId(1);
    let bufferSize =
      BlockProductScale.SIZE_2HA_1250 * BlockProductScale.SCALE_1250;
    bufferInfoFeature.set("description", bufferSize + "m buffer");
    this._vectorLayer.getSource().addFeature(bufferInfoFeature);
    this.areaDrawn.emit(this._extent);
    //set zoom level
    setTimeout(() => {
      this.setZoomLevels();
    }, 2000);
  }
  drawLargePlanArea(paperSize: string, scale) {
    this.removeLargePlanFeature();
    this.removeLocationFeature();
    this.resetZoomLevels();
    let planNameHt = "Large_1250_" + paperSize + "_HEIGHT";
    let planNameWd = "Large_1250_" + paperSize + "_Width";
    let scale_factor = BlockProductScale.SCALE_1250;
    switch (scale) {
      case "1:1250":
        scale_factor = BlockProductScale.SCALE_1250;
        break;
      case "1:2500":
        scale_factor = BlockProductScale.SCALE_2500;
        break;
      case "1:500":
        scale_factor = BlockProductScale.SCALE_500;
        break;
    }
    if (sessionStorage.getItem("x")) {
      this._center = [
        Number(sessionStorage.getItem("x")),
        Number(sessionStorage.getItem("y")),
      ];
      sessionStorage.removeItem("x");
    }
    const infoStyleFunction: (feature: Feature, resolution: number) => Style[] =
      function () {
        return [
          new Style({
            stroke: new Stroke({
              color: "transparent",
              width: 2,
              lineDash: [5, 5],
            }),
          }),
        ];
      };
    // Add information feature according to type

    let bufferInfoFeature = this.getBlockPlanFeature(
      BlockProductScale[planNameHt],
      scale_factor,
      BlockProductScale[planNameWd]
    );
    let extent = bufferInfoFeature.getGeometry().getExtent();

    this._extent = extent as [number, number, number, number];
    setTimeout(() => {
      this._map.getView().fit(extent, {
        duration: 200, // Animation duration in milliseconds (optional)
        easing: function (t) {
          return t; // Linear easing function
        },
      });
      this._map.renderSync();
    }, 300);

    bufferInfoFeature.setStyle(infoStyleFunction);
    bufferInfoFeature.setId(3);
    this._vectorLayer.getSource().addFeature(bufferInfoFeature);
    this.areaDrawn.emit(extent);
    setTimeout(() => {
      this.setZoomLevels();
    }, 2500);
  }

  setCenter(feature: Feature) {
    let polygon = feature.getGeometry().getExtent();
    let center = getCenter(polygon);
    this._center = center;
  }

  setCenterFromXY(ctr) {
    this._center = ctr;
  }

  setCenterfromExtent(extent) {
    this._map.getView().fit(extent);
    this._center = this._map.getView().getCenter();
  }

  private getBlockPlanFeature(size, scale, width?: any): Feature {
    let dist = (size * scale) / 2;
    //let dist = size/2;
    let wd = width ? (width * scale) / 2 : (size * scale) / 2;

    let x = this._center[0];
    let y = this._center[1];
    let polyCoords = [];
    let coords = [x - wd, y - dist];
    let coords2 = [x - wd, y + dist];
    let coords3 = [x + wd, y + dist];
    let coords4 = [x + wd, y - dist];
    polyCoords.push(coords);
    polyCoords.push(coords2);
    polyCoords.push(coords3);
    polyCoords.push(coords4);
    polyCoords.push(coords);
    let polygon = new Polygon([polyCoords]);

    var feature = new Feature({
      geometry: polygon,
    });
    return feature;
  }

  removeBlockFeature() {
    let feature = (<VectorSource>this._source).getFeatureById("2");
    if (feature) {
      (<VectorSource>this._source).removeFeature(feature);
    }
  }

  removeLocationFeature() {
    let feature = (<VectorSource>this._source).getFeatureById("1");
    if (feature) {
      (<VectorSource>this._source).removeFeature(feature);
    }
  }
  removeLargePlanFeature() {
    let feature = (<VectorSource>this._source).getFeatureById("3");
    if (feature) {
      (<VectorSource>this._source).removeFeature(feature);
    }
  }

  setFeatureColor(color: string, strk: number, action: FeatureAction) {
    this._strokeColor = color;
    this._strokeWidth = strk;
    this.colorChanged = true;

    switch (action) {
      case FeatureAction.DELETE:
        this.deleteFeature();
        break;
      case FeatureAction.DRAWSHAPE:
        this.drawShape();
        break;
      case FeatureAction.DRAWCIRCLE:
        this.drawCircle(false);
        break;
      case FeatureAction.DRAWRECTANGLE:
        this.drawCircle(true);
        break;
      case FeatureAction.REDO:
        this.redo();
        break;
      case FeatureAction.UNDO:
        this.undo();
        break;
      case FeatureAction.TEXT:
        this.addTextToMap();
        break;
      case FeatureAction.DRAWLINE:
        this.drawShape(true);
        break;
    }

    //get feature on the map
    if (this._feature) {
      this._feature.set("selected", true);

      let id = this.selectedFeature.getId();
      var feature = this._vectorLayer2.getSource().getFeatureById(id);
      if (feature) {
        var style = feature.get("originalStyle");
        var st = new Style({
          stroke: new Stroke({
            color: color,
            width: style.getStroke().getWidth(), // Preserve existing stroke style
          }),
        });
        feature.setStyle(st);
        feature.set("originalStyle", st.clone());
        this._vectorLayer2.changed();
        let change = {
          originalColor: style.getStroke().getColor(),
          changedColor: color,
        };
        //send request to update legend
        this.updateLegendColor.emit(change);
      }
    }
  }

  setFeatureStroke(strk_width, color) {
    this._strokeWidth = strk_width;
    //get feature on the map
    if (this._feature) {
      this._feature.setStyle(
        new Style({
          stroke: new Stroke({
            width: strk_width,
            color: color ? color : "#222222",
          }),
        })
      );
    }
  }

  public getExtent() {
    let ext = this._map ? this._map.getView().calculateExtent() : this._extent;
    return ext;
  }

  getExtentForProduct(productId, paperSize, scale) {
    let size;
    // let scale;
    let extent;
    let width = null;
    //get scale and size from prodId
    if (
      productId === PlansProductId.BWLocationPlan ||
      productId == PlansProductId.ColorLocationPlan
    ) {
      //location plan
      size = BlockProductScale.BASE_1250_A4_SIZE;
      scale = BlockProductScale.SCALE_1250;
    } else {
      if (
        productId === PlansProductId.BWBlockPlan ||
        productId === PlansProductId.ColorBlockPlan
      ) {
        size = BlockProductScale.BASE_500_SIZE;
        scale = BlockProductScale.SCALE_500_BASE;
      } else {
        if (
          productId == PlansProductId.COLOR2HA ||
          productId == PlansProductId.BW2HA
        ) {
          //2ha plan
          size = BlockProductScale.SIZE_2HA_1250;
          scale = BlockProductScale.SCALE_1250;
        } else {
          if (
            productId === PlansProductId.ColorLocationA3 ||
            productId === PlansProductId.BWLocationA3
          ) {
            //A3
            size = BlockProductScale.BASE_1250_A3_SIZE;
            scale = BlockProductScale.SCALE_1250;
          } else {
            if (
              productId === PlansProductId.BWLocationA3SCALE ||
              productId === PlansProductId.ColorLocationA3SCALE
            ) {
              //A3
              size = BlockProductScale.BASE_1250_A3_SIZE;
              switch (scale) {
                case "1:2500":
                  scale = BlockProductScale.SCALE_2500;
                  break;
                case "1:500":
                  scale = BlockProductScale.SCALE_500;
                  break;
              }
            } else {
              if (
                productId === PlansProductId.ColorLocationA42500 ||
                productId === PlansProductId.BWLOCAIONA42500
              ) {
                //A3
                size = BlockProductScale.BASE_1250_A4_SIZE;
                switch (scale) {
                  case "1:2500":
                    scale = BlockProductScale.SCALE_2500;
                    break;
                  case "1:500":
                    scale = BlockProductScale.SCALE_500;
                    break;
                }
              } else {
                //large plan
                let keyHt = "Large_1250_" + paperSize + "_HEIGHT";
                let wdKey = "Large_1250_" + paperSize + "_Width";
                size = BlockProductScale[keyHt];
                if (scale) {
                  switch (scale) {
                    case "1:1250":
                      scale = BlockProductScale.SCALE_1250;
                      break;
                    case "1:2500":
                      scale = BlockProductScale.SCALE_2500;
                      break;
                    case "1:500":
                      scale = BlockProductScale.SCALE_500;
                      break;
                  }
                }

                width = BlockProductScale[wdKey];
              }
            }
          }
        }
      }
    }
    let bufferInfoFeature = this.getBlockPlanFeature(size, scale, width);
    extent = bufferInfoFeature.getGeometry().getExtent();
    return extent;
  }

  //return feature information as required for savePlan
  getShapeFeatures() {
    let outputFeatures = [];
    let features = this._vectorLayer2.getSource().getFeatures();
    features.forEach((feature) => {
      let ft = feature.getGeometry();
      //let style = feature.getStyle();
      let style = feature.get("originalStyle");
      let color;
      let width;
      if (style) {
        color = style.getStroke()
          ? style.getStroke().getColor()
          : this._strokeColor;
        width = style.getStroke()
          ? style.getStroke().getWidth()
          : this._strokeWidth;
      } else {
        console.log("style does not exist");
      }
      if (feature.getGeometry().getType() === "Circle") {
        ft = fromCircle(feature.getGeometry());
      }

      //get shape and style and create array
      let format = new WKT();
      let wkt = format.writeGeometry(ft);
      let obj = {
        Wkt: wkt,
        Colour: color,
        Stroke: width,
        Id: feature.getId(),
      };
      outputFeatures.push(obj);
    });

    return outputFeatures;
  }

  getMapFeatures() {
    let features = this._vectorLayer2.getSource().getFeatures();
    let featuresCopy: Collection<Feature> = new Collection<Feature>();
    for (let i = 0, ii = features.length; i < ii; i++) {
      let id = features[i].getId();

      let featureClone = features[i].clone();
      featureClone.setId(id);
      featuresCopy.push(featureClone);
    }
    return features;
  }

  addSelectInteraction() {
    //create select interaction
    const selected = new Style({
      fill: new Fill({
        color: "rgba(211, 211, 211, 0.5)",
      }),
      stroke: new Stroke({
        color: "black",
        width: 2,
        lineDash: [5, 5],
      }),
    });

    function selectStyle(feature) {
      return selected;
    }
    if (this._map) {
      var selectInteraction = new Select({
        //style: selectStyle,
        style: selectStyle,
        condition: singleClick,
        layers: [this._vectorLayer2, this._vectorLayer3],
      });
      this._selectInteraction = selectInteraction;
      this._map.addInteraction(this._selectInteraction);

      this._selectInteraction.on("select", (event) => {
        // this.updateFeatureProperty();
        this.pickfeatureSelected = false;
        var selectedFeatures = event.selected;
        if (selectedFeatures.length) {
          this.colorChanged = false;
          this.selectedFeature =
            selectedFeatures.length > 1
              ? selectedFeatures[1]
              : selectedFeatures[0];
          this._feature = this.selectedFeature;
          const geometry = this.selectedFeature.getGeometry();
          let featureInfo = this.getFeatureInfo(geometry);

          this.featureSelected.emit(featureInfo);
          //detect changes in selected feature
          this.selectedFeature.on("change", () => {
            const geom = this.selectedFeature.getGeometry();
            let featureInfo = this.getFeatureInfo(geom);
            // Handle feature changes here
            this._featureService.updateFeatureProperty(featureInfo);
            this._undoRedoService.pushCurrentMapState(this.getMapState());
          });
        }
        //reset style to changed color or stroke as openlayers select interaction resets the style to original style
        if (event.deselected.length > 0) {
          var deselectedFeatures = event.deselected;
          if (deselectedFeatures.length) {
            this.featureDeselected.emit();
            let ft = deselectedFeatures[0];
            let originalStyle = ft.get("originalStyle");
            let origColor = originalStyle.getStroke().getColor();
            let st = new Style({
              fill: new Fill({
                color: "transparent",
              }),
              stroke: new Stroke({
                color: this.colorChanged ? this._strokeColor : origColor,
                width: this._strokeWidth,
              }),
            });
            ft.setStyle(st);
          }
          //let the landing page know the polygon is deselected
          this.hmlrSelected.emit(false);
        }
        // }
      });
    }
  }

  setBaseLayer(layerType: PlanColorType) {
    let i = layerType === PlanColorType.Colour ? 0 : 1;
    this._layerIndex = i;
    let source = this.getWmsSource(this._layers[i]);
    this._baseTileLayer.setSource(source);
    this._baseTileLayer.getSource().refresh();
    this._baseTileLayer.setVisible(true);
  }

  removeDragpanInteraction() {
    let interactions = this._map.getInteractions();
    //get draginteraction
    let drag = interactions.item(1);
    this._map.removeInteraction(drag);
  }

  public addHmlrPolygon(polygons, centre: boolean = false, extent: any) {
    // Generate a feature out of the Wkt and set styles for it
    polygons.forEach((polygon) => {
      if (polygon) {
        let wkt = new WKT();
        let feature = new Feature(wkt.readFeature(polygon).getGeometry());
        let center = getCenter(extent);
        this._center = center;
        let style = new Style({
          stroke: new Stroke({
            color: "#FA0000",
            width: 3,
          }),
          fill: new Fill({
            color: [255, 255, 255, 0.01],
          }),
        });
        feature.setStyle(style);
        feature.set("featureStyle", style);
        feature.set("name", "hmlrPolygon");
        this._vectorLayer3.getSource().addFeature(feature);
        if (centre) {
          setTimeout(() => {
            this.centreMapAndZoom(center);
          }, 200);
        }
      }
    });
  }

  addShapeFeatures(shapePolygons) {
    shapePolygons.forEach((ft) => {
      let wkt = new WKT();
      let feature = new Feature(wkt.readFeature(ft["Wkt"]).getGeometry());

      let style = new Style({
        stroke: new Stroke({
          color: ft["Colour"],
          width: ft["Stroke"],
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.01],
        }),
      });
      feature.setStyle(style);
      feature.set("originalStyle", style);
      feature.setId(ft["Id"]);
      this._feature = feature;
      this._vectorLayer2.getSource().addFeature(feature);
      const modify = new Modify({ source: <VectorSource>this._source2 });
      this._map.addInteraction(modify);
      this._featureCount++;
    });
  }

  getMap() {
    return this._map;
  }

  //reset the map to initial state
  resetMap() {
    setTimeout(() => {
      this.drawLocationPlanArea("A4", "1:1250");
    }, 200);
  }

  public addHMLRPolygonAsWkt(shapes, centre: boolean = false) {
    shapes.forEach((shape) => {
      if (shape) {
        let wkt = new WKT();
        let feature = new Feature(wkt.readFeature(shape).getGeometry());
        let style = new Style({
          stroke: new Stroke({
            color: "#FA0000",
            width: 3,
          }),
          // Fill required, even though it isn't really visible, to enable proper selection
          fill: new Fill({
            color: [255, 255, 255, 0.01],
          }),
        });
        feature.setStyle(style);
        feature.set("featureStyle", style);
        this._vectorLayer3.getSource().addFeature(feature);
      }
    });
  }

  public changePaperSize(
    paperSize: string,
    customPlanSelected: boolean,
    templateSelection: PlanTemplates,
    scale: string
  ) {
    this.customPlanSelected = customPlanSelected;
    const infoStyleFunction: (feature: Feature, resolution: number) => Style[] =
      function () {
        return [
          new Style({
            stroke: new Stroke({
              color: "transparent",
              width: 2,
              lineDash: [5, 5],
            }),
          }),
        ];
      };
    let bufferSize;
    let bufferInfoFeature;
    let scale_factor;
    switch (scale) {
      case "1:1250":
        scale_factor = BlockProductScale.SCALE_1250;
        break;
      case "1:2500":
        scale_factor = BlockProductScale.SCALE_2500;
        break;
      case "1:500":
        scale_factor = BlockProductScale.SCALE_500;
        // size_key = 'BASE_500_SIZE'
        break;
    }

    if (
      templateSelection == PlanTemplates.Standard ||
      templateSelection == PlanTemplates.SelectTemplate
    ) {
      // bufferInfoFeature = this.getBlockPlanFeature(BlockProductScale.BASE_1250_A4_SIZE,BlockProductScale.SCALE_1250);
      bufferInfoFeature = this.getBlockPlanFeature(
        BlockProductScale.BASE_1250_A4_SIZE,
        scale_factor
      );
      switch (paperSize) {
        case "A2":
          bufferInfoFeature = this.getBlockPlanFeature(
            BlockProductScale.BASE_1250_A2_SIZE,
            scale_factor
          );
          bufferSize =
            BlockProductScale.BASE_1250_A2_SIZE * BlockProductScale.SCALE_1250;
          break;
        case "A3":
          bufferInfoFeature = this.getBlockPlanFeature(
            BlockProductScale.BASE_1250_A3_SIZE,
            scale_factor
          );
          bufferSize =
            BlockProductScale.BASE_1250_A3_SIZE * BlockProductScale.SCALE_1250;
          break;
        case "A4":
          bufferInfoFeature = this.getBlockPlanFeature(
            BlockProductScale.BASE_1250_A4_SIZE,
            scale_factor
          );
          break;
      }
    } else {
      //large template
      switch (paperSize) {
        // case 'A2': bufferInfoFeature = this.getBlockPlanFeature(BlockProductScale.BASE_1250_A2_SIZE,BlockProductScale.SCALE_1250);
        //             bufferSize = BlockProductScale.BASE_1250_A2_SIZE * BlockProductScale.SCALE_1250
        //             break;
        case "A3":
          bufferInfoFeature = this.getBlockPlanFeature(
            BlockProductScale.Large_1250_A3_HEIGHT,
            scale_factor,
            BlockProductScale.Large_1250_A3_Width
          );
          bufferSize =
            BlockProductScale.BASE_1250_A3_SIZE * BlockProductScale.SCALE_1250;
          break;
        case "A4":
          bufferInfoFeature = this.getBlockPlanFeature(
            BlockProductScale.Large_1250_A4_HEIGHT,
            scale_factor,
            BlockProductScale.Large_1250_A4_Width
          );
          break;
      }
    }

    let extent = bufferInfoFeature.getGeometry().getExtent();
    this._extent = extent;
    bufferInfoFeature.setStyle(infoStyleFunction);
    if (
      templateSelection === PlanTemplates.Standard ||
      templateSelection === PlanTemplates.SelectTemplate
    )
      bufferInfoFeature.setId(1);
    else bufferInfoFeature.setId(3);

    bufferInfoFeature.set("description", bufferSize + "m buffer");
    this._vectorLayer.getSource().addFeature(bufferInfoFeature);
    setTimeout(() => {
      this._map.getView().fit(extent);
      setTimeout(() => {
        this.setZoomLevels();
      }, 500);
    }, 1000);
  }

  getAllFeaturesColor() {
    let colorList = [];
    //iterate on each layer and get all the features
    let features = this._vectorLayer3.getSource().getFeatures();
    if (features.length) {
      features.forEach((ft) => {
        let style = ft.get("featureStyle")
          ? ft.get("featureStyle")
          : ft.get("originalStyle");
        let clr = style.getStroke().getColor();
        colorList.push(clr);
      });
    }
    let shapFts = this._vectorLayer2.getSource().getFeatures();
    if (shapFts.length) {
      shapFts.forEach((ft) => {
        let clr = ft.getStyle().getStroke().getColor();
        colorList.push(clr);
      });
    }

    //get feature style and color
    return colorList;
  }

  addShapeFeature(feature: Feature) {
    let ft = new Feature(feature.getGeometry());
    // let style = feature.get('OriginalStyle');

    let style = new Style({
      stroke: new Stroke({
        color: this._strokeColor,
        width: 2,
      }),
      fill: new Fill({
        color: [255, 255, 255, 0.01],
      }),
    });
    ft.setStyle(style);
    this._feature = ft;
    this._vectorLayer2.getSource().addFeature(ft);
    //  (<VectorSource>this._source2).addFeature(ft)
  }

  setZoomLevels() {
    //set minzoom to the current zoom level to stop it from zooming out of the extent
    const view = this._map.getView();
    const currentZoom = view.getZoom();
    const maxZoom = this._maxZoom; // Replace with your desired maximum zoom level
    // Set the minimum and maximum zoom levels
    view.setMinZoom(currentZoom);
    view.setMaxZoom(maxZoom);
  }

  resetZoomLevels() {
    const view = this._map.getView();
    view.setMinZoom(this._minZoom);
    view.setMaxZoom(this._maxZoom);
  }

  showZoomControl() {
    //this._zoomControl.setZoomControlVisible(true);
    this._planSelected = true;
    this._map.addControl(this._zoomControl);
  }

  hideZoomControl() {
    this._planSelected = false;
    this._map.removeControl(this._zoomControl);
  }

  pickFeature(point) {
    this._featureCount++;
    //let pt = {x: point.eastings, y: point.northings};
    this._mappingService
      .PickFeature(this._pickFeatureURL, {
        x: point.eastings,
        y: point.northings,
      })
      .subscribe(
        (response) => {
          if (this._drawInteraction) {
            //   this._map.removeInteraction(this._drawInteraction);
          }
          if (response[0].Rows[0]) {
            let wkt = new WKT();

            let feature = new Feature(
              wkt
                .readFeature(response[0].Rows[0].Columns.st_astext)
                .getGeometry()
            );
            this._pickFeatureGeom = response[0].Rows[0].Columns.st_astext;
            this._geoms.push(feature);
            let style = new Style({
              stroke: new Stroke({
                color: this._strokeColor ? this._strokeColor : "blue",
                width: this._strokeWidth ? this._strokeWidth : 2,
              }),
              fill: new Fill({
                color: [255, 255, 255, 0.01],
              }),
            });
            feature.setStyle(style);
            feature.set("originalStyle", style);
            feature.setId("ShapeFT" + this._featureCount);
            this._vectorLayer2.getSource().addFeature(feature);
            this._feature = feature;
          } else {
            alert("No feature could be found at the selected location.");
          }
          this._undoRedoService.pushCurrentMapState(this.getMapState());
        },
        (error) => {
          console.log("error");
        }
      );
  }

  public addPickFeatureInteraction() {
    if (!this.pickfeatureSelected) {
      this._selectInteraction.setActive(false);
      this._map.removeInteraction(this._selectInteraction);
      this.pickfeatureSelected = true;
      let drawInteraction = new Draw({
        type: GeometryType.POINT,
        style: new Style({
          stroke: new Stroke({
            color: "blue",
            width: 2,
          }),
        }),
        condition: (evt) => {
          // Get modify key state
          this.modifyKeyPressed = platformModifierKeyOnly(evt);
          return true;
        },
        freehandCondition: (evt) => {
          // Get modify key state
          this.modifyKeyPressed = platformModifierKeyOnly(evt);
          return false;
        },
      });
      this._map.addInteraction(drawInteraction);
      this._drawInteraction = drawInteraction;
      drawInteraction.on("drawend", (e) => {
        this._selectInteraction.setActive(false);
        if (e.feature.getGeometry().getType() === "Point") {
          let point: any = this.getENPoint(e.feature);
          //If modify key being pressed, add feature to existing, otherwise pick new
          if (this.modifyKeyPressed) {
            if (this._pickFeatureGeom)
              //if first feature being picked
              this.PickFeatureMultiple(point);
            else this.pickFeature(point);
          } else {
            this.pickFeature(point);
          }
        }
        if (this.modifyKeyPressed) {
          //this.mergeGeometries(e.feature);
        }
      });
    } else {
      //tool already selected deselect the tool
      this._selectInteraction.setActive(true);
      this._map.addInteraction(this._selectInteraction);
      const modify = new Modify({ source: <VectorSource>this._source2 });
      this._map.addInteraction(modify);
      this.pickfeatureSelected = false;
    }
  }
  getENPoint(pt) {
    let point = <Point>pt.getGeometry();
    let eastings = Math.round(point.getCoordinates()[0]);
    let northings = Math.round(point.getCoordinates()[1]);
    return {
      eastings: eastings,
      northings: northings,
    };
  }

  public PickFeatureMultiple(point: any) {
    this._mappingService
      .PickFeature(this._pickFeatureURL, {
        x: point.eastings,
        y: point.northings,
      })
      .subscribe((response) => {
        if (response[0].Rows[0]) {
          let wkt = new WKT();

          if (this._pickFeatureGeom) {
            var dissolved;
            dissolved = this.mergePolygon(
              this._pickFeatureGeom,
              response[0].Rows[0].Columns.st_astext
            );
            this._geoms[0] = dissolved.clone();
            this._pickFeatureGeom = wkt.writeGeometry(dissolved.getGeometry());
            this._vectorLayer2.getSource().clear();
            this._vectorLayer2.getSource().addFeature(dissolved);
          }
        } else {
          alert(" No feature found at the selected location");
        }
      });
  }

  public mergeGeometries(ft: any) {
    if (this._geoms[0]) {
      const polygon1 = this._geoms[0].getGeometry();
      const polygon2 = ft.getGeometry();

      let mergedCoordinates = polygon1
        .getCoordinates()
        .concat(polygon2.getCoordinates());

      // Create a new Polygon geometry from the merged coordinates
      const mergedPolygon = new Polygon(mergedCoordinates);

      let newft = new Feature(mergedPolygon);
      let style = new Style({
        stroke: new Stroke({
          color: "blue",
          width: 2,
        }),
        fill: new Fill({
          color: [255, 255, 255, 0.01],
        }),
      });
      newft.setStyle(style);
      newft.set("originalStyle", style);
      newft.set("featureStyle", style);
      this._geoms[0] = newft.clone();
      this._vectorLayer2.getSource().addFeature(newft);
    }
  }
  addTextToMap() {
    this.addingText = true;
    var input = document.createElement("input");
    input.type = "text";
    input.className = "textOnMap";
    input.value = "";
    input.placeholder = "Type and click outside to add";
    var overlay = new ol.Overlay({
      element: input,
    });
    var lonLat;
    // Add an event listener to the map
    this._map.on("click", (event) => {
      if (this.addingText) {
        this.addingText = false;
        // Get the coordinates of the click
        lonLat = event.coordinate;
        this.overlayPosition = lonLat;
        overlay.setPosition(lonLat);
        this._map.addOverlay(overlay);
        input.focus();
        this.actionFinished.emit();
      } else {
        //set the value in the array with location
        //if the text element does not already exists in the array
        let el = this.textArray.find((element) => {
          return element.value == input.value;
        });
        if (!el && input.value !== "") {
          this.textArray.push({
            position: lonLat,
            value: input.value,
          });
          //show text on map
          var span = document.createElement("span");
          span.setAttribute("name", "maptext");
          span.setAttribute("style", "background:white");
          span.setAttribute("contenteditable", "true");
          span.innerText = input.value;
          var textOverlay = new ol.Overlay({
            element: span,
          });
          textOverlay.setPosition(lonLat);
          this._map.addOverlay(textOverlay);

          input.value = "";
        }
        this._map.removeOverlay(overlay);
        //for editing
        const mapTxtElements = document.getElementsByName("maptext");
        mapTxtElements.forEach((editableElement) => {
          editableElement.addEventListener("input", (event) => {
            const editedText = (event.target as HTMLElement).innerText;
            let el = this.textArray.find((element) => {
              return element.position == lonLat;
            });
            let index = this.textArray.indexOf(el);
            this.textArray[index].value = editedText;
          });
        });
      }
    });
  }

  addTextEditMode(textArr: []) {
    textArr.forEach((text: any) => {
      var span = document.createElement("span");
      span.setAttribute("name", "maptext");
      span.setAttribute("style", "background:white");
      span.setAttribute("contenteditable", "true");
      span.innerText = text.value;
      var textOverlay = new ol.Overlay({
        element: span,
      });
      textOverlay.setPosition(text.position);
      this._map.addOverlay(textOverlay);
      span.addEventListener("input", (event) => {
        const editedText = (event.target as HTMLElement).innerText;
        let el = this.textArray.find((element) => {
          return element.position == text.position;
        });
        let index = this.textArray.indexOf(el);
        this.textArray[index].value = editedText;
      });
    });
  }

  removeAllInteractions() {
    if (this._drawInteraction) {
      this._map.removeInteraction(this._drawInteraction);
    }
  }

  getTextArray() {
    let noOfTxtField = document.getElementsByClassName("textOnMap").length;
    if (noOfTxtField) {
      let lastElement = document.getElementsByClassName("textOnMap")[
        noOfTxtField - 1
      ] as HTMLInputElement;
      this.textArray.push({
        position: this.overlayPosition,
        value: lastElement.value,
      });
    }
    return this.textArray;
  }

  setMapExtents(extent) {
    this._extent = extent;
    this._map.getView().fit(extent, {
      duration: 500, // Animation duration in milliseconds (optional)
      easing: function (t) {
        return t; // Linear easing function
      },
    });
    this._map.renderSync();

    setTimeout(() => {
      this.areaDrawn.emit(this._extent);
    }, 600);
  }

  getFeatureInfo(geometry: Geometry) {
    let area = 0;
    let height = 0;
    let width = 0;
    let perimeterLine: LineString;
    let perimeter = 0;
    let extent = geometry.getExtent();
    let precision = 100; //for 2 decimal places

    if (geometry instanceof Polygon) {
      const x_min = Math.round(extent[0] * precision) / precision;
      const y_min = Math.round(extent[1] * precision) / precision;
      const x_max = Math.round(extent[2] * precision) / precision;
      const y_max = Math.round(extent[3] * precision) / precision;
      width = x_max - x_min;
      height = y_max - y_min;

      if (this._shapeType === Shape_Type.Rectangle) {
        area = width * height;
        perimeter = 2 * (width + height);
      } else {
        area = geometry.getArea();
        perimeterLine = new LineString(
          geometry.getLinearRing(0).getCoordinates()
        );
        perimeter = Number(perimeterLine.getLength().toFixed(2));
      }
    } else if (geometry instanceof Circle) {
      if (this._shapeType === Shape_Type.Circle) {
        area = Math.PI * Math.pow(geometry.getRadius(), 2);
        width = 2 * geometry.getRadius();
        height = 2 * geometry.getRadius();
        perimeter = 2 * Math.PI * geometry.getRadius();
      }
    } else if (geometry instanceof MultiPolygon) {
      //hmlr polygon, report to landing page to disable style change
      this.hmlrSelected.emit(true);
      area = geometry.getArea();
      let extent = geometry.getExtent();
      width = extent[2] - extent[0]; // maxX - minX
      height = extent[3] - extent[1];
      perimeter = getLength(geometry);
    } else {
      area = 0;
      let extent = geometry.getExtent();
      width = extent[2] - extent[0]; // maxX - minX
      height = extent[3] - extent[1];
      perimeter = 0;
    }

    return {
      area: area,
      width: width,
      height: height,
      perimeter: perimeter,
    };
  }

  deselectShape() {
    if (this.selectedFeature) {
      this.featureDeselected.emit();

      let originalStyle = this.selectedFeature.get("originalStyle");
      let origColor = originalStyle.getStroke().getColor();
      let st = new Style({
        fill: new Fill({
          color: "transparent",
        }),
        stroke: new Stroke({
          color: this.colorChanged ? this._strokeColor : origColor,
          width: this._strokeWidth,
        }),
      });
      this.selectedFeature.setStyle(st);
    }
  }

  mergePolygon(wkt1: string, wkt2: string) {
    const wktFormat = new WKT();
    const geojsonFormat = new GeoJSON();

    // Create OpenLayers features from WKT
    const feature1 = wktFormat.readFeature(wkt1);
    const feature2 = wktFormat.readFeature(wkt2);

    // Convert OpenLayers geometries to JSTS geometries
    const reader = new GeoJSONReader();
    const writer = new GeoJSONWriter();

    const geom1 = geojsonFormat.writeGeometryObject(feature1.getGeometry());
    const geom2 = geojsonFormat.writeGeometryObject(feature2.getGeometry());

    const jstsGeom1 = reader.read(geom1);
    const jstsGeom2 = reader.read(geom2);

    // Merge (union) the geometries
    const mergedGeometryJsts = OverlayOp.union(jstsGeom1, jstsGeom2);

    // Convert back to OpenLayers feature
    const mergedGeoJson = writer.write(mergedGeometryJsts);
    const mergedOlGeometry = geojsonFormat.readGeometry(mergedGeoJson);
    const mergedFeature = new Feature(mergedOlGeometry);
    let style = new Style({
      stroke: new Stroke({
        color: this._strokeColor,
        width: this._strokeWidth,
      }),
      fill: new Fill({
        color: [255, 255, 255, 0.01],
      }),
    });
    // this._feature = new Feature(e.feature);
    mergedFeature.setStyle(style);
    mergedFeature.set("originalStyle", style);
    mergedFeature.setId("ShapeFT" + this._featureCount);

    return mergedFeature;
  }
}
